<template>
  <!-- 投保-上门取货-结算确认 成功页面 -->
  <div>
    <div class="bgcenter"></div>
    <div>
      <headbox
        :title="titleinfo"
        bgColor="#fff"
        isborder="true"
        :initback="true"
        @initbackf="backpage"
        :backIcon="backIcon"
      ></headbox>
      <!-- 卖出成功 -->
      <div class="centerbox" v-if="sellbool">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">定价提交成功</div>
        <div class="infobox">我们将尽快为您审核,请留意短信通知</div>
        <div class="seedata" @click="seedata">查看详情</div>
        <el-button class="btnbox" @click="pricingRecordslog"
          >知道了  ({{number}})</el-button
        >
        <!-- <div class="flex-around footerbox">
          <div @click="pickUpPage" class="btnstyle">上门取货</div>
          <div @click="seedata" class="btnstyle">查看行情</div>
        </div> -->
      </div>  
          <!-- 人脸认证成功 -->
      <div class="centerbox" v-if="face">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">资料提交成功</div>
        <div class="infobox">我们将在1个工作日内将审核结果发送您的手机</div>
        <el-button class="btnbox" @click="seedata"
          >查看行情</el-button
        >
      </div>          
      <!-- 实名认证成功 -->
      <div class="centerbox" v-if="usercerti">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">资料提交成功</div>
        <div class="infobox">我们将在1个工作日内完成资料审核,请稍等</div>
        <el-button class="btnbox" @click.native="seedata">查看行情</el-button>
      </div>
      <!-- 买料成功 -->
      <div class="centerbox" v-if="buybool">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">买料提交成功</div>
        <div class="infobox">我们将尽快为您审核,请留意短信通知</div>
         <div class="seedata" @click="seedata">查看详情</div>
        <el-button class="btnbox" @click="buypricingRecordslog"
          >知道了  ({{number}})</el-button
        >
        <!-- <div class="flex-around footerbox">
          <div @click="pickUpPage" class="btnstyle">上门取货</div>
          <div @click="seedata" class="btnstyle">查看行情</div>
        </div> -->
      </div>
      <!-- 结算 -->
      <div class="centerbox" v-if="jiesuan">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">您的结算单已确认</div>
        <div class="infobox">飞速打款中，请留意银行短信通知</div>
        <el-button class="btnbox" @click="seedata">
          查看行情
        </el-button>
      </div>
      <!-- 上门结算 -->
      <div class="centerbox" v-if="shangmen">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">预约成功</div>
        <div class="infobox">取货人员正在飞速赶来</div>
        <el-button class="btnbox" @click="claimedit">立即投保</el-button>
        <div class="footerbox " @click="seedata">
          <div class="btnstyle" style="width:100%">查看行情</div>
        </div>
      </div>
      <!-- 注册成功 -->
      <div class="centerbox" v-if="zhuce">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">注册成功</div>
        <div class="infobox"></div>
        <el-button class="btnbox" @click="gologin">前往登录</el-button>
      </div>
      <!-- 重置密码成功 -->
      <div class="centerbox" v-if="chongzhimima">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">重置密码成功</div>
        <div class="infobox"></div>
        <el-button class="btnbox" @click="gologin">前往登录</el-button>
      </div>
      <!-- 投保 -->
      <div class="centerbox" v-if="toubao">
        <!--  -->
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <!-- <img src="@/assets/images/successbox.png" alt=""> -->
        <div class="titlebox">投保申请已提交</div>
        <div class="infobox">稍后可在“我的保险”查看投保结果</div>
        <el-button class="btnbox" @click="myclaim">查看保单</el-button>
        <div class="footerbox " @click="seedata">
          <div class="btnstyle" style="width:100%">返回行情</div>
        </div>
      </div>
      <!-- 卖料违约结算成功 -->
      <div class="centerbox" v-if="Default">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">订单提交成功</div>
         <div class="seedata" @click="seeBreakContractData">查看详情</div>
        <el-button class="btnbox" @click="breakContractKnow"
          >知道了  ({{number}})</el-button
        >
        <!-- <div class="flex-around footerbox">
          <div @click="pickUpPage" class="btnstyle">上门取货</div>
          <div @click="seeBreakContractData" class="btnstyle">查看详情</div>
        </div> -->
      </div> 
      <!-- 买料违约结算成功 -->
      <div class="centerbox" v-if="buyDefault">
        <img  src="@/assets/images/pic_success@2x.png" class="icon" />
        <div class="titlebox">订单提交成功</div>
         <div class="seedata" @click="seeBreakContractData">查看详情</div>
        <el-button class="btnbox" @click="buybreakContractKnow"
          >知道了  ({{number}})</el-button
        >
        <!-- <div class="flex-around footerbox">
          <div @click="pickUpPage" class="btnstyle">上门取货</div>
          <div @click="seeBreakContractData" class="btnstyle">查看详情</div>
        </div> -->
      </div>  
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
export default {
  data() {
    return {
      titleinfo: "",
      sellbool: false,
      jiesuan: false,
      buybool: false,
      shangmen: false,
      toubao: false,
      zhuce: false,
      chongzhimima: false,
      face:false,
      usercerti:false,
      backIcon:true,
      Default:false,
      buyDefault:false,
      number:'',
      time:null
    };
  },
  mounted() {
    const querytype = this.$route.query.type;
    if (querytype == "sell") {
      this.titleinfo = "卖出";
      this.number = 3
      this.sellbool = true;
     this.time = setInterval(()=>{
        this.number--
        if (this.number == 0) {
             this.$router.push({ name: "sellSettlement" });
        }
      },1000)

    } else if (querytype == "buy") {
      this.titleinfo = "买料";
      this.buybool = true;
      this.number = 3
    this.time =   setInterval(()=>{
        this.number--
        if (this.number == 0) {
             this.$router.push({ name: "buyOrder" });
        }
      },1000)
    } else if (querytype == "jiesuan") {
      this.titleinfo = "结算确认";
      this.jiesuan = true;
    } else if (querytype == "shangmen") {
      this.titleinfo = "上门取货";
      this.shangmen = true;
    } else if (querytype == "toubao") {
      this.titleinfo = "投保";
      this.toubao = true;
    } else if (querytype == "zhuce") {
      this.titleinfo = "注册";
      this.zhuce = true;
    } else if (querytype == "chongzhimima") {
      this.titleinfo = "重置密码";
      this.chongzhimima = true;
    }else if(querytype == "face"){
      this.backIcon = false
      this.titleinfo = "实名认证";
      this.face = true
    }else if(querytype == "usercerti"){
      this.titleinfo = "实名认证";
      // 取消左侧按钮
      this.backIcon = false
      this.usercerti = true
    }else if(querytype == "Default"){
      this.titleinfo = "违约结算";
      this.number = 3
      this.Default = true
    this.time =   setInterval(()=>{
        this.number--
        if (this.number == 0) {
             this.$router.push({ name: "sellSettlement",query:{active:'Settlement'} });
        }
      },1000)
    }else if(querytype == "buyDefault"){
      this.titleinfo = "违约结算";
      this.number = 3
      this.buyDefault = true
    this.time =   setInterval(()=>{
        this.number--
        if (this.number == 0) {
             this.$router.push({ name: "buyOrder",query:{active:'buyJieSuan'} });
        }
      },1000)
    }
   


  },
  destroyed(){
      clearInterval(this.time)
  },
  methods: {
    backpage() {
      const querytype = this.$route.query.type;
      if (querytype == "sell") {
        this.$router.push({ name: "person" });
      } else if (querytype == "jiesuan") {
        this.$router.push({ name: "person" });
      } else if (querytype == "shangmen") {
        this.$router.push({ name: "person" });
      } else if (querytype == "toubao") {
        this.$router.push({ name: "person" });
      } else if (querytype == "zhuce") {
        this.$router.push({ name: "login" });
      } else if (querytype == "chongzhimima") {
        this.$router.push({ name: "login" });
      } else if (querytype == "face") {
        this.$router.push({ name: "home" });
      } else if (querytype == "usercerti") {
        this.$router.push({ name: "home" });
      } else if (querytype == "Default") {
        this.$router.push({ name: "sellSettlement" ,query:{active:'Settlement'}});
      } else if (querytype == "buy") {
        this.$router.push({ name: "buyOrder"});
      } else if (querytype == "buyDefault") {
        this.$router.push({ name: "buyOrder",query:{active:'buyJieSuan'}});
      }
    },
    // 前往登录
    gologin() {
      this.$router.push({ name: "login" });
    },
    // 买料定价
    buypricingRecordslog() {
      this.$router.push({ name: "buyOrder" });

    },

      pricingRecordslog() {
      this.$router.push({ name: "sellSettlement" });

    },
    // 查看保单
    myclaim() {
      this.$router.push({ name: "claimedit",query:{active:'b'} });
    },
    // 查看详情
    seedata() {
      this.$router.push({ name: "home" });
    },
    // 填写投保信息
    claimedit() {
      this.$router.push({ name: "claimedit" });
    },
    // 上门取货
    pickUpPage() {
      this.$router.push({ name: "pickUp" });
    },
    // 违约结算
    breakContractKnow(){
      this.$router.push({ name: "sellSettlement",query:{active:'Settlement'} });
    },
    buybreakContractKnow(){
      this.$router.push({ name: "buyOrder",query:{active:'buyJieSuan'} });

    },
    // 违约结算详情
    seeBreakContractData(){
      this.$router.push({ name: "sellSettlement" ,query:{active:'Settlement'}});
    }
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.hear{
  width: 100%;
  height: 100px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  color: #212121;
  font-size: 34px;
  text-align: center;
  line-height: 100px;
  z-index: 2 !important;
}
.icon{
  width: 160px;
  height: 160px
  ;
}
.btnstyle {
  width: 330px;
  padding: 25px 0;
  border-radius: 5px;
  background: #fff;
}
.bgcenter {
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.centerbox {
  position: relative;
  z-index: 2;
  text-align: center;
  i {
    margin-top: 80px;
    display: block;
    font-size: 170px;
    color: #1fc88c;
  }
  .el-button {
    border-radius: 8px;
  }
  .titlebox {
    margin-top: 36px;
    font-size: 36px;
    color: #242424;
  }
  .infobox {
    color: #999999;
    margin-top: 14px;
    font-size: 28px;
  }
  .seedata{
    color: #FF2D2E;
        margin-top: 4px;
    font-size: 28px;
  }
  .footerbox {
    padding: 0 30px;
    font-size: 32px;
    color: #333333;
  }
  .btnbox {
    width: 90%;
    background: #FF2D2E;
    color: #fff;
    margin: 50px auto 30px;
    border-radius: 50px;
    display: block;
    height: 90px;
    font-size: 36px;
  }
  img {
    width: 200px;
    height: 181px;
    margin: 90px auto 0;
    display: block;
  }
}
</style>
